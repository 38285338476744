import Participants from "./Participants";
import Cazare from "./Cazare";
import TopButtons from "./TopButtons";
import NumePrenume from "./NumePrenume";
import Observatii from "./Observatii";
import AddToCalendar from "../AddToCalendar/AddToCalendar";

import classes from "./Form.module.scss";

import { useState } from "react";
import { useFormik } from "formik";
import { Button } from "@mui/material";
import * as Yup from "yup";
import { Container, Row, Col } from "react-bootstrap";

import { addParticipant } from "../../firebase-config";
import CircularProgress from "@mui/material/CircularProgress";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

const Form = () => {
  const [showForm, setShowForm] = useState(true);
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(null);
  const [topButtonsActive, setTopButtonsActive] = useState(false);
  const [showMainContent, setShowMainContent] = useState(null);
  const [showActions, setShowActions] = useState(false);
  const [adulti, setAdulti] = useState(null);
  const [copii, setCopii] = useState(null);
  const [participating, setParticipating] = useState(false);

  const aa = localStorage.getItem("aa");

  const onNumePrenumeHandler = (event) => {
    formik.setFieldValue(
      "numePrenume",
      event.target.value,
      !!formik.touched.numePrenume
    );
    !!event.target.value.trim()
      ? setTopButtonsActive(true)
      : setTopButtonsActive(false);
  };

  const onNumePrenumeFocusHandler = (milliseconds) => {
    let slide_3 = document.getElementById("slide_3");
    let slide_2 = document.getElementById("slide_2");
    slide_2.style.visibility = "hidden";
    setTimeout(() => {
      slide_3.scrollIntoView({
        block: "start",
        // behavior: "smooth",
        inline: "nearest",
      });
    }, milliseconds);
    slide_2.style.visibility = "visible";
  };

  const onParticipHandler = () => {
    setShowMainContent(true);
    setShowActions(true);
    setParticipating(true);
    onNumePrenumeFocusHandler("100");
  };

  const onNuParticipHandler = () => {
    setShowMainContent(false);
    setShowActions(true);
    setParticipating(false);
    onNumePrenumeFocusHandler("100");
  };

  const onAdultiChanged = (numarAdulti) => {
    formik.setFieldValue("adulti", numarAdulti, !!formik.touched.adulti);
    setAdulti(numarAdulti);
  };
  const onMeniuriCopiiChanged = (meniuriCopii) => {
    formik.setFieldValue(
      "meniuriCopii",
      meniuriCopii,
      !!formik.touched.meniuriCopii
    );
  };

  const onCopiiChanged = (numarCopii) => {
    formik.setFieldValue("copii", numarCopii, !!formik.touched.copii);
    setCopii(numarCopii);
  };
  const onMeniuriAdultiChanged = (meniuriAdulti) => {
    formik.setFieldValue(
      "meniuriAdulti",
      meniuriAdulti,
      !!formik.touched.meniuriAdulti
    );
  };

  const onCazareChanged = (cazare) => {
    formik.setFieldValue("cazare", cazare, !!formik.touched.cazare);
  };

  const onObservatiiChange = (observatii) => {
    let el = document.getElementById(`${classes.save}`);
    el.scrollIntoView({
      block: "start",
      behavior: "smooth",
      inline: "end",
    });
    formik.setFieldValue("observatii", observatii);
  };

  const formik = useFormik({
    initialValues: {
      numePrenume: "",
      adulti: "",
      meniuriAdulti: [],
      copii: "",
      meniuriCopii: [],
      cazare: "",
      observatii: "",
    },
    validationSchema: Yup.object({
      numePrenume: Yup.string()
        .max(30, "Numele poate conține maxim 30 de caractere!")
        .matches(
          /^([A-Za-z-ăâîșțĂÂÎȘȚ'" ]*)$/,
          "Acest câmp acceptă doar diacritice românești!"
        )
        .required("Acest câmp este obligatoriu!"),
      adulti:
        showMainContent &&
        Yup.string().required("Acest câmp este obligatoriu!"),
      meniuriAdulti:
        showMainContent &&
        Yup.array().length(
          adulti,
          "Selectați tipul meniului pentru toți participanții!"
        ),
      copii:
        showMainContent &&
        Yup.string().required("Acest câmp este obligatoriu!"),
      meniuriCopii:
        showMainContent &&
        copii &&
        Yup.array().length(
          copii,
          "Selectați tipul meniului pentru toți participanții!"
        ),
      cazare:
        showMainContent &&
        Yup.string().required("Acest câmp este obligatoriu!"),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      setSaving(true);
      addParticipant({
        nume_prenume: values.numePrenume,
        participa: showMainContent ? "Da" : "Nu",
        adulti: showMainContent && values.adulti,
        meniuri_adulti: showMainContent && values.meniuriAdulti,
        copii: showMainContent && values.copii,
        meniuri_copii: showMainContent && values.meniuriCopii,
        cazare: showMainContent && values.cazare,
        observatii: showMainContent && values.observatii,
      })
        .then(() => {
          setSuccess(true);
          localStorage.setItem("aa", true);
        })
        .catch(() => {
          setSuccess(false);
        })
        .finally(() => {
          setSaving(false);
        });
      onNumePrenumeFocusHandler("100");
      setShowForm(false);
    },
  });

  return (
    <>
      {success === null && aa && (
        <Container className={`text-center ${classes["end-message"]}`}>
          <Row className="justify-content-md-center">
            <Col>
              <h3>
                Ați răspuns deja la invitație.
                <br />
                Pentru orice modificări sau nelămuriri vă rugăm să ne contactați
                la telefon{" "}
                <a className={classes.anchor} href="tel:+40746034062">
                  0746034062
                </a>{" "}
                (Bela) sau{" "}
                <a className={classes.anchor} href="tel:+40747364843">
                  0747364843
                </a>{" "}
                (Monica).
              </h3>
              <SentimentSatisfiedAltIcon />
              {participating && <AddToCalendar />}
            </Col>
          </Row>
        </Container>
      )}
      {success === true && (
        <Container className={`text-center ${classes["end-message"]}`}>
          <Row className="justify-content-md-center">
            <Col>
              <h3>
                Răspunsul a fost salvat.
                <br />
                Vă mulțumim!
              </h3>
              <SentimentSatisfiedAltIcon />
              {participating && <AddToCalendar />}
            </Col>
          </Row>
        </Container>
      )}
      {!aa && success === false && (
        <Container className={`text-center ${classes["end-message"]}`}>
          <Row className="justify-content-md-center">
            <Col>
              <h3>A apărut o eroare! Vă rugăm încercați mai târziu!</h3>
              <SentimentVeryDissatisfiedIcon />
            </Col>
          </Row>
        </Container>
      )}
      {saving && (
        <Container className={`text-center ${classes["end-message"]}`}>
          <Row className="justify-content-md-center">
            <Col>
              <CircularProgress />
              <p>Salvez...</p>
            </Col>
          </Row>
        </Container>
      )}
      {!aa && showForm && (
        <form
          onSubmit={formik.handleSubmit}
          className={classes["invitation-form"]}
        >
          <Container className="text-center">
            <Row className="justify-content-md-center">
              <NumePrenume
                id="numePrenume"
                value={formik.values.numePrenume}
                numePrenumeHandler={onNumePrenumeHandler}
                numePrenumeFocusHandler={onNumePrenumeFocusHandler}
                errorNumePrenume={formik.errors.numePrenume}
              />
            </Row>
            <TopButtons
              participHandler={onParticipHandler}
              nuParticipHandler={onNuParticipHandler}
              participa={showMainContent}
              disabled={!topButtonsActive}
            />
            <div
              className={`${classes["main-content"]} ${
                showMainContent ? classes.show : classes.hide
              }`}
            >
              <Row className="justify-content-md-center">
                <Col>
                  <h5>Câte persoane veți participa?</h5>
                </Col>
              </Row>
              <Row className="justify-content-md-center">
                <Participants
                  value={formik.values.adulti}
                  participantsChanged={onAdultiChanged}
                  meniuriChanged={onMeniuriAdultiChanged}
                  errorParticipants={formik.errors.adulti}
                  errorMeniuri={formik.errors.meniuriAdulti}
                  plural="Adulți"
                  singular="adult"
                />
                <Participants
                  value={formik.values.copii}
                  participantsChanged={onCopiiChanged}
                  meniuriChanged={onMeniuriCopiiChanged}
                  errorParticipants={formik.errors.copii}
                  errorMeniuri={formik.errors.meniuriCopii}
                  plural="Copii"
                  singular="copil"
                />
              </Row>
              <Row className="justify-content-md-center">
                <Col xs="12" md="12" lg="6">
                  <Cazare
                    cazareChanged={onCazareChanged}
                    errorCazare={formik.errors.cazare}
                  />
                </Col>
                <Observatii
                  value={formik.values.observatii}
                  observatiiChanged={onObservatiiChange}
                />
              </Row>
            </div>
            <div
              className={`${classes["form-actions"]} ${
                showActions ? classes.show : classes.hide
              }`}
            >
              <Row className="justify-content-md-center">
                <Col xs="12" md="12" lg="12">
                  <Button
                    id={classes.save}
                    type="submit"
                    variant="contained"
                    color="success"
                    size="large"
                  >
                    Salvează
                  </Button>
                </Col>
              </Row>
            </div>
          </Container>
          {/* <pre>{JSON.stringify(formik, null, 2)}</pre> */}
        </form>
      )}
    </>
  );
};

export default Form;
