import "./App.css";

import Parallax from "./Components/Parallax/Parallax";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div id="nuntaWrapper" className="container-fluid">
      <Parallax />
    </div>
  );
}

export default App;
