import classes from "./Location.module.scss";

import { Button } from "@mui/material";

const Location = (props) => {
  const indication = () => {
    window.open(props.indication, "_blank", "noopener,noreferrer");
  };
  return (
    <div className={classes.location}>
      <div
        className={classes.image}
        style={{ backgroundImage: `url(${props.image})` }}
      ></div>
      <div className={classes.content}>
        <div className={classes.text}>{props.children}</div>
        <div className={classes.indications}>
          <Button
            className={`${props.participa === true && classes.selected}`}
            type="button"
            variant="contained"
            color="success"
            onClick={indication}
          >
            Indicații &nbsp;<i className="fas fa-location-arrow"></i>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Location;
