import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { collection, addDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD1DwcI5VBN8c0F9ZyAIoMsfIWetpl6huQ",
  authDomain: "nunta-7a1d7.firebaseapp.com",
  projectId: "nunta-7a1d7",
  storageBucket: "nunta-7a1d7.appspot.com",
  messagingSenderId: "666179306741",
  appId: "1:666179306741:web:ea53edcd08e064f1b431bc",
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const participantsCollection = collection(db, "participanti");

export const addParticipant = async (obj) => {
  // console.log("Saving...");
  await addDoc(participantsCollection, obj);
};
