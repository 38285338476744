import classes from "./Misc.module.scss";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const Cazare = (props) => {
  const cazareHandler = (event) => {
    props.cazareChanged(event.target.value);
  };
  return (
    <div className={classes["content-column"]}>
      {!!props.errorCazare && (
        <p className={classes.invalid}>{props.errorCazare}</p>
      )}
      <FormControl>
        <FormLabel id="cazareLabel">Aveți nevoie de cazare?</FormLabel>
        <RadioGroup
          row
          aria-labelledby="cazareLabel"
          name="cazare"
          sx={{ justifyContent: "center" }}
          onChange={cazareHandler}
        >
          <FormControlLabel value="da" control={<Radio />} label="Da" />
          <FormControlLabel value="nu" control={<Radio />} label="Nu" />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default Cazare;
