import classes from "./Misc.module.scss";

import { Col } from "react-bootstrap";
import FormControl from "@mui/material/FormControl";
import { TextField } from "@mui/material";

const NumePrenume = (props) => {
  const scrollTop = () => {
    props.numePrenumeFocusHandler("300");
  };
  return (
    <Col
      className={`${classes["content-column"]} ${classes["move-right"]}`}
      xs="12"
      md="12"
      lg="12"
    >
      <FormControl fullWidth component="fieldset">
        {props.errorNumePrenume && (
          <p className={classes.invalid}>{props.errorNumePrenume}</p>
        )}
        <TextField
          autoComplete="off"
          label="Nume și prenume"
          id="numePrenume"
          margin="dense"
          onChange={props.numePrenumeHandler}
          onFocus={scrollTop}
          value={props.value}
        />
      </FormControl>
    </Col>
  );
};

export default NumePrenume;
