import classes from "./Misc.module.scss";
import styles from "./Observatii.module.scss";
import { Col } from "react-bootstrap";
import TextareaAutosize from "@mui/material/TextareaAutosize";

const Observatii = (props) => {
  const observatiiChangeHandler = (event) => {
    props.observatiiChanged(event.target.value);
  };
  return (
    <Col
      className={`${classes["content-column"]} ${styles.observatii}`}
      xs="12"
      md="12"
      lg="6"
    >
      <TextareaAutosize
        minRows={6}
        maxRows={7}
        aria-label="Textarea Observații"
        placeholder="Observații"
        style={{ width: "100%", height: "98%" }}
        onKeyUp={observatiiChangeHandler}
      />
    </Col>
  );
};

export default Observatii;
