import { Container, Row, Col } from "react-bootstrap";
import Scroll from "./Scroll";
import classes from "./Parallax.module.scss";
import Form from "../Form/Form";
import noi5 from "../../Media/Images/noi5.jpg";
import noi4 from "../../Media/Images/noi4.jpg";
import millenium from "../../Media/Images/millenium.jpg";
import annes from "../../Media/Images/annes.jpg";

import Location from "../Location/Location";
import Countdown from "../Countdown/Countdown";

const Parallax = () => {
  return (
    <Container className={classes.parallax} fluid="xs">
      <Row className={classes.section} id={classes.slide_0}>
        <Col style={{ backgroundImage: `url(${noi5})` }} xs="12" md="12">
          <div className={classes.content}>
            <Countdown
              date="8/26/2023"
              color="white"
              introText1="Vă invităm la nunta noastră în"
              introText2="26 august 2023"
            />
          </div>
          <Scroll scrollTo={classes.slide_1} />
        </Col>
      </Row>
      <Row className={classes.section} id={classes.slide_1}>
        <Col
          className={classes["media-left"]}
          style={{ backgroundImage: `url(${noi4})` }}
          xs="12"
          md="12"
          lg="6"
        ></Col>
        <Col className={classes.text} xs="12" md="12" lg="12" xl="12" xxl="6">
          <div>
            <div className={classes.wedding}>
              <p>
                În sfârșit, ne-am hotărât!
                <br />
                Ca și căsătoriți (de câțiva ani) acum facem nuntă în toată
                regula...
                <br />
                ...nași, biserică, chef.
                <br />
                Alături de Agatia și Bogdan Ciobanu, nașii noștrii, vă invităm
                să ne fiți alături pe data de <strong>26 august 2023</strong> *.
                <br />
                Informații suplimentare și formularul de răspuns le găsiți mai
                jos. **
              </p>
            </div>
            <div id={classes["mobile-wedding"]}>Bela și Moni</div>
            <p>
              * Vă rugăm să confirmaţi prezenţa până la data de{" "}
              <strong>1 august 2023.</strong>
            </p>
            <p>
              ** Pentru orice nelămuriri ne puteți contacta la telefon{" "}
              <a className={classes.anchor} href="tel:+40746034062">
                0746034062
              </a>{" "}
              (Bela) sau{" "}
              <a className={classes.anchor} href="tel:+40747364843">
                0747364843
              </a>{" "}
              (Monica).
            </p>
          </div>
          <Scroll scrollTo="slide_2" />
        </Col>
      </Row>
      <Row className={classes.section} id="slide_2">
        <Col
          className={`${classes.text} ${classes.top}`}
          xs="12"
          md="12"
          lg="12"
          xl="12"
          xxl="6"
        >
          <div className={classes.locations}>
            <Location
              image={millenium}
              indication="https://www.google.com/maps/place/Magyarok+Nagyasszonya+Temesvar+Millenium+Church/@45.7567257,21.2470832,18z/data=!4m5!3m4!1s0x47456764863959ff:0x521f36806a0e356e!8m2!3d45.7566667!4d21.2477778"
            >
              <h5>CUNUNIA RELIGIOASĂ</h5>
              <p>
                <i>Biserica Millenium, Timișoara, Piaţa Romanilor Nr. 2</i>
                <br />
                <strong>Ora 15:00</strong>
              </p>
            </Location>
            <Location
              image={annes}
              indication="https://www.google.com/maps/place/Anne's+Event+Ballroom/@45.7213044,21.31026,17.5z/data=!4m5!3m4!1s0x47455e36f1f75eed:0x7a9cc9a0db0a09c2!8m2!3d45.7212477!4d21.3107732"
            >
              <h5>PETRECEREA</h5>
              <p>
                <i>
                  Anne's Event Ballroom, Moșnița Nouă, Strada Căliman, Nr. 1
                </i>
                <br />
                <strong>Ora 19:00</strong>
              </p>
            </Location>
          </div>
          <div className={classes["countdown-wrapper"]}>
            <Countdown date="8/26/2023" color="black" />
          </div>
          <Scroll scrollTo="slide_3" />
        </Col>
        <Col className={classes["media-right"]} xs="12" md="12" lg="6">
          <video autoPlay muted playsInline loop>
            <source
              src={require("../../Media/Videos/wedding-mobile-2.mov")}
              type="video/mp4"
            />
            Your browser does not support HTML5 video.
          </video>
        </Col>
      </Row>
      <Row
        className={classes.section}
        id="slide_3"
        // style={{ overflow: "hidden auto" }}
      >
        <Col className={classes["media-left"]} xs="12" md="12" lg="6">
          <video autoPlay muted playsInline loop>
            <source
              src={require("../../Media/Videos/glasses.mov")}
              type="video/mp4"
            />
            Your browser does not support HTML5 video.
          </video>
        </Col>
        <Col className={classes.form} xs="12" md="12" lg="12" xl="12" xxl="6">
          <Form />
        </Col>
      </Row>
    </Container>
  );
};

export default Parallax;
