import classes from "./TopButtons.module.scss";

import { Row, Col } from "react-bootstrap";
import { Button } from "@mui/material";

const TopButtons = (props) => {
  return (
    <Row className={`justify-content-md-center ${classes["form-top-buttons"]}`}>
      <Col xs="12" md="12" lg="6">
        <Button
          disabled={props.disabled}
          className={`${props.participa === true && classes.selected}`}
          type="button"
          variant="contained"
          color="success"
          size="large"
          onClick={props.participHandler}
        >
          Particip
        </Button>
      </Col>
      <Col xs="12" md="12" lg="6">
        <Button
          disabled={props.disabled}
          className={`${props.participa === false && classes.selected}`}
          type="button"
          variant="contained"
          color="error"
          size="large"
          onClick={props.nuParticipHandler}
        >
          Nu particip
        </Button>
      </Col>
    </Row>
  );
};

export default TopButtons;
