import classes from "./Scroll.module.scss";

const Scroll = (props) => {
  const scrollToNextSlide = (event) => {
    event.preventDefault();
    let e = document.getElementById(props.scrollTo);
    e.scrollIntoView({
      block: "start",
      behavior: "smooth",
      inline: "start",
    });
  };
  return (
    <div className={classes.scroll}>
      <a href={props.scrollTo} onClick={scrollToNextSlide}>
        <span></span>
        <span></span>
        <span></span>
        <p>Scroll</p>
      </a>
    </div>
  );
};

export default Scroll;
