import classes from "./Misc.module.scss";
import styles from "./Participants.module.scss";

import { useState } from "react";
import { Col } from "react-bootstrap";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const Participants = (props) => {
  const [participantsArray, setParticipantsArray] = useState([]);
  const [meniuriParticipants, setMeniuriParticipants] = useState({});

  const participantsHandler = (event) => {
    const newParticipantsArray = [];
    props.participantsChanged(event.target.value);
    for (var i = 1; i <= event.target.value; i++) {
      newParticipantsArray.push(i);
    }
    setParticipantsArray(newParticipantsArray);
    setMeniuriParticipants({});
  };

  const meniuriAdultiHandler = (event) => {
    const meniuri = { ...meniuriParticipants };
    meniuri[`${event.target.name}`] = event.target.value;
    setMeniuriParticipants(meniuri);
    props.meniuriChanged(Object.values(meniuri));
  };

  return (
    <Col className={classes["content-column"]} xs="12" md="12" lg="6">
      {!!props.errorParticipants && (
        <p className={classes.invalid}>{props.errorParticipants}</p>
      )}

      {!!props.errorMeniuri && !props.errorParticipants && (
        <p className={classes.invalid}>{props.errorMeniuri}</p>
      )}
      <FormControl fullWidth sx={{ margin: 1 }}>
        <InputLabel id={props.plural}>{props.plural}</InputLabel>
        <Select
          className={styles["mui-select"]}
          id={props.plural}
          name={props.plural}
          labelId={`${props.plural}Label`}
          label={props.plural}
          value={props.value}
          onChange={participantsHandler}
        >
          {props.singular === "copil" && (
            <MenuItem value={0}>Niciunul</MenuItem>
          )}
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
        </Select>
      </FormControl>
      {participantsArray.map((participant) => {
        return (
          <FormControl
            fullWidth
            sx={{ margin: 1 }}
            key={`meniu${props.plural}${participant}`}
          >
            <InputLabel id={`meniu${props.plural}Label${participant}`}>
              {`Meniu ${props.singular} ${participant}`}
            </InputLabel>
            <Select
              className={styles["mui-select"]}
              id={`meniu${props.plural}${participant}`}
              name={`meniu${props.plural}${participant}`}
              labelId={`meniu${props.plural}Label${participant}`}
              label={`Meniu ${props.singular} ${participant}`}
              value={
                meniuriParticipants[`meniu${props.plural}${participant}`] || ""
              }
              onChange={meniuriAdultiHandler}
            >
              <MenuItem value={1}>Meniu normal</MenuItem>
              <MenuItem value={2}>Meniu normal (fără porc)</MenuItem>
              <MenuItem value={3}>Meniu vegetarian</MenuItem>
            </Select>
          </FormControl>
        );
      })}
    </Col>
  );
};

export default Participants;
